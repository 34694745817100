<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <h1 class="title is-4">
                    {{ $t('profile.header', { name: profile.firstName }) }}
                </h1>
                <p class="content">
                    {{ $t('profile.description') }}
                </p>
                <template v-if="profile">
                    <form @submit.prevent="updateProfile">
                        <b-field grouped group-multiline>
                            <b-field
                                :label="$t('profile.firstName')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="profile.firstName"
                                    icon="account"
                                />
                            </b-field>

                            <b-field
                                :label="$t('profile.lastName')"
                                expanded
                                label-position="inside"
                            >
                                <b-input
                                    v-model="profile.lastName"
                                    icon="account"
                                />
                            </b-field>

                            <b-field
                                :label="$t('profile.phone')"
                                expanded
                                label-position="inside"
                            >
                                <b-input v-model="profile.phone" icon="phone" />
                            </b-field>
                        </b-field>

                        <b-field grouped group-multiline>
                            <b-field
                                :label="$t('general.email')"
                                expanded
                                label-position="inside"
                            >
                                <b-input
                                    v-model="profile.email"
                                    type="email"
                                    icon="email"
                                    maxlength="180"
                                    required
                                />
                            </b-field>

                            <b-field
                                :label="$t('profile.username')"
                                expanded
                                label-position="inside"
                            >
                                <b-input
                                    v-model="profile.username"
                                    icon="account"
                                    disabled
                                    required
                                />
                            </b-field>
                        </b-field>

                        <b-field grouped group-multiline>
                            <b-field
                                expanded
                                label-position="inside"
                                :label="$t('profile.password')"
                            >
                                <b-input
                                    v-model="newPassword"
                                    type="password"
                                    maxlength="180"
                                    icon="lock"
                                />
                            </b-field>
                        </b-field>

                        <b-field>
                            <b-button
                                class="is-pulled-right"
                                type="is-success"
                                native-type="submit"
                            >
                                {{ $t('profile.buttons.update') }}
                            </b-button>
                        </b-field>
                    </form>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Profile',
    data() {
        return {
            newPassword: null,
            profile: {
                firstName: '',
                lastName: '',
                phone: null,
                username: '',
                email: '',
                usergroup: {
                    name: ''
                }
            }
        }
    },
    methods: {
        async updateProfile() {
            if (!this.newPassword) {
                this.newPassword = undefined
            }

            await this.$store.dispatch('user/updateUser', {
                iri: this.profile.iri,
                email: this.profile.email,
                password: this.newPassword,
                firstName: this.profile.firstName,
                lastName: this.profile.lastName,
                phone: this.profile.phone
            })
            if (!this.$store.getters['user/hasError']) {
                this.$buefy.snackbar.open({
                    message: this.$t('profile.update.success'),
                    actionText: 'Ok',
                    queue: false
                })
                this.newPassword = ''
            } else {
                this.$buefy.snackbar.open({
                    duration: 5000,
                    message: this.$t('profile.update.failed'),
                    type: 'is-danger',
                    position: 'is-bottom-left',
                    actionText: 'Ok',
                    queue: false
                })
            }
        }
    },
    created() {
        this.profile = this.$store.getters['security/user']
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
